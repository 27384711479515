
import { defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { ElNotification } from 'element-plus'

import YhForm from '@/base-ui/form'

export default defineComponent({
  components: {
    YhForm
  },
  props: {
    modalConfig: {
      type: Object,
      required: true
    },
    defaultInfo: {
      type: Object,
      default: () => ({})
    },
    pageName: {
      type: String,
      require: true
    },
    dialogWidth: {
      type: String,
      default: '30%'
    },
    rules: {
      type: Object
    }
  },
  setup(props) {
    const dialogVisible = ref(false)
    const formData = ref<any>({})
    const disabled = ref(false)

    watch(
      () => props.defaultInfo,
      (newValue) => {
        for (const item of props.modalConfig.formItems) {
          formData.value[`${item.field}`] = newValue[`${item.field}`]
        }
      }
    )

    // 点击确定按钮的逻辑
    const store = useStore()
    let refs: any
    const formValue = (vm: any) => {
      refs = vm
    }
    const handleConfirmClick = () => {
      refs.validate((vaild: any) => {
        if (vaild) {
          dialogVisible.value = false
          if (Object.keys(props.defaultInfo).length) {
            // 编辑
            // console.log('编辑用户')
            const result = store.dispatch('system/editPageDataAction', {
              pageName: props.pageName,
              editData: { ...formData.value },
              id: props.defaultInfo.id,
              query: store.state.system.queryInfo,
              pageInfo: store.state.system.pageInfo
            })
            result.then((res) => {
              if (res.code === 0) {
                ElNotification({
                  title: '编辑成功',
                  message: res.message,
                  type: 'success'
                })
              } else {
                ElNotification({
                  title: '编辑失败',
                  message: res.message,
                  type: 'error'
                })
              }
            })
          } else {
            // 新建
            // console.log('新建用户')
            const result = store.dispatch('system/createPageDataAction', {
              pageName: props.pageName,
              newData: { ...formData.value },
              query: store.state.system.queryInfo,
              pageInfo: store.state.system.pageInfo
            })
            result.then((res) => {
              if (res.code === 0) {
                ElNotification({
                  title: '新建成功',
                  message: res.message,
                  type: 'success'
                })
              } else {
                ElNotification({
                  title: '新建失败',
                  message: res.message,
                  type: 'error'
                })
              }
            })
          }
        }
      })
    }

    return {
      dialogVisible,
      formData,
      disabled,
      formValue,
      handleConfirmClick
    }
  }
})
