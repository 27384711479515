export const rules = {
  name: [
    {
      required: true,
      message: '用户名不能为空！',
      trigger: 'blur'
    }
  ],
  password: [
    {
      required: true,
      message: '密码不能为空！',
      trigger: 'blur'
    }
  ],
  roleId: [
    {
      required: true,
      message: '权限角色不能为空！',
      trigger: 'blur'
    }
  ]
}
