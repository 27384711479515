import { ref } from 'vue'
import PageModal from '@/components/page-modal'

type CallbackFn = () => void

export function usePageModal(
  newCb?: CallbackFn,
  editCb?: CallbackFn,
  seeCb?: CallbackFn
) {
  const pageModalRef = ref<InstanceType<typeof PageModal>>()
  const defaultInfo = ref({})
  const handleSeeData = (item: any) => {
    defaultInfo.value = { ...item }
    if (pageModalRef.value) {
      pageModalRef.value.dialogVisible = true
      pageModalRef.value.disabled = true
    }
    editCb && editCb()
    seeCb && seeCb()
  }

  const handleNewData = () => {
    defaultInfo.value = {}
    if (pageModalRef.value) {
      pageModalRef.value.dialogVisible = true
      pageModalRef.value.disabled = false
    }
    newCb && newCb()
  }

  const handleEditData = (item: any) => {
    defaultInfo.value = { ...item }

    if (pageModalRef.value) {
      pageModalRef.value.dialogVisible = true
      pageModalRef.value.disabled = false
    }
    editCb && editCb()
  }

  return [
    pageModalRef,
    defaultInfo,
    handleSeeData,
    handleNewData,
    handleEditData
  ]
}
