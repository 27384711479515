import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "user" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_search = _resolveComponent("page-search")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_page_content = _resolveComponent("page-content")!
  const _component_page_modal = _resolveComponent("page-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_page_search, {
      searchFormConfig: _ctx.searchFormConfig,
      onResetBtnClick: _ctx.handleResetClick,
      onQueryBtnClick: _ctx.handleQueryClick
    }, null, 8, ["searchFormConfig", "onResetBtnClick", "onQueryBtnClick"]),
    _createVNode(_component_page_content, {
      ref: "pageContentRef",
      contentTableConfig: _ctx.contentTableConfig,
      pageName: "users",
      btnName: "新建用户",
      onSeeBtnClick: _ctx.handleSeeData,
      onNewBtnClick: _ctx.handleNewData,
      onEditBtnClick: _ctx.handleEditData
    }, {
      role: _withCtx((scope) => [
        (scope.row.roleId === 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "管理员"))
          : _createCommentVNode("", true),
        (scope.row.roleId === 2)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "普通用户"))
          : _createCommentVNode("", true)
      ]),
      image: _withCtx((scope) => [
        _createVNode(_component_el_image, {
          style: {"width":"60px","height":"60px"},
          src: scope.row.avatarUrl,
          "preview-src-list": [scope.row.avatarUrl]
        }, null, 8, ["src", "preview-src-list"])
      ]),
      _: 1
    }, 8, ["contentTableConfig", "onSeeBtnClick", "onNewBtnClick", "onEditBtnClick"]),
    _createVNode(_component_page_modal, {
      ref: "pageModalRef",
      modalConfig: _ctx.ModalConfigRef,
      defaultInfo: _ctx.defaultInfo,
      rules: _ctx.rules,
      pageName: "users",
      dialogWidth: "30%"
    }, null, 8, ["modalConfig", "defaultInfo", "rules"])
  ]))
}