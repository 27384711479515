import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-modal" }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_yh_form = _resolveComponent("yh-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      title: _ctx.modalConfig.title,
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      width: _ctx.dialogWidth,
      center: "",
      "destroy-on-close": ""
    }, _createSlots({
      default: _withCtx(() => [
        _createVNode(_component_yh_form, _mergeProps(_ctx.modalConfig, {
          modelValue: _ctx.formData,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData) = $event)),
          disabled: _ctx.disabled,
          rules: _ctx.rules,
          onFormValue: _ctx.formValue
        }), null, 16, ["modelValue", "disabled", "rules", "onFormValue"])
      ]),
      _: 2
    }, [
      (!_ctx.disabled)
        ? {
            name: "footer",
            fn: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, [
                _createVNode(_component_el_button, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialogVisible = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("取 消")
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleConfirmClick()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" 确 定 ")
                  ]),
                  _: 1
                })
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["title", "modelValue", "width"])
  ]))
}