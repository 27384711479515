import { IForm } from '@/base-ui/form'

export const searchFormConfig: IForm = {
  title: '新建用户',
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: '用户名:',
      placeholder: '请输入用户名'
    },
    {
      field: 'roleId',
      type: 'select',
      label: '权限名称:',
      placeholder: '请选择权限名称',
      options: [
        { title: '管理员', value: 1 },
        { title: '普通用户', value: 2 }
      ]
    },
    {
      field: 'enable',
      type: 'select',
      label: '用户状态:',
      placeholder: '请选择用户状态',
      options: [
        { title: '启用', value: 1 },
        { title: '禁用', value: 0 }
      ]
    }
  ]
}
