
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import { searchFormConfig } from './config/search.config'
import { contentTableConfig } from './config/content.config'
import { modalConfig } from './config/modal.config'
import { rules } from './config/modal.rules.config'

import PageSearch from '@/components/page-search'
import PageContent from '@/components/page-content'
import PageModal from '@/components/page-modal'

import { usePageSearch } from '@/hooks/usePageSearch'
import { usePageModal } from '@/hooks/usePageModal'

export default defineComponent({
  name: 'users',
  components: {
    PageSearch,
    PageContent,
    PageModal
  },
  setup() {
    const [pageContentRef, handleResetClick, handleQueryClick] = usePageSearch()
    const newCallback = () => {
      const passwordItem = modalConfig.formItems.find(
        (item) => item.field === 'password'
      )
      modalConfig.title = '新建用户'
      passwordItem!.isHidden = false
    }
    const editCallback = () => {
      const passwordItem = modalConfig.formItems.find(
        (item) => item.field === 'password'
      )
      modalConfig.title = '编辑用户'
      passwordItem!.isHidden = true
    }
    const seeCallback = () => {
      modalConfig.title = '查看用户'
    }
    const [
      pageModalRef,
      defaultInfo,
      handleSeeData,
      handleNewData,
      handleEditData
    ] = usePageModal(newCallback, editCallback, seeCallback)

    // 动态添加角色列表
    const store = useStore()
    const ModalConfigRef = computed(() => {
      const roleItem = modalConfig.formItems.find(
        (item) => item.field === 'roleId'
      )
      roleItem!.options = store.state.entireRole.map((item) => {
        return { title: item.name, value: item.id }
      })
      return modalConfig
    })

    return {
      searchFormConfig,
      contentTableConfig,
      pageContentRef,
      handleResetClick,
      handleQueryClick,
      ModalConfigRef,
      rules,
      handleSeeData,
      handleNewData,
      handleEditData,
      pageModalRef,
      defaultInfo
    }
  }
})
